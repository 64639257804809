const env = process.env.NODE_ENV || "development";
const config = require("@/config/config")[env];

import _ from "lodash";
import axios from "../../lib/axios";
import notify from "@/utilities/notifications";

const studentsModule = {
  namespaced: true,
  state: {
    advisory: [],
    section: null,
    sections: [],
    limit: true,
    taskDialog: false,
    student: {},
    students: [],
    studentsAssessment: [],
    studentsQuery: {
      query: "",
      quarter: "",
      type: "",
      sectionId: "",
      subjectId: "",
    },
    studentPoints: [],
  },
  getters: {},
  mutations: {
    seeMoreSections(state, payload) {
      if (payload) {
        state.limit = payload.show;
      } else {
        state.limit = !state.limit;
      }
    },
    toggleTaskDialog(state, student) {
      state.taskDialog = !state.taskDialog;
      if (student) state.student = student;
    },
    setSection(state, payload) {
      state.section = payload;
    },
    setSections(state, payload) {
      state.sections = payload;
    },
    setStudentsQuery(state, payload) {
      state.studentsQuery = {
        ...state.studentsQuery,
        [payload.key]: payload.value,
      };
    },
    deleteAStudentQuery(state, payload) {
      delete state.studentsQuery[`${payload}`];
    },
    resetStudentsQuery(state) {
      state.studentsQuery = {
        query: "",
        quarter: state.studentsQuery.quarter ? state.studentsQuery.quarter : "",
        type: "",
        sectionId: "",
        subjectId: "",
      };
    },
    setStudents(state, payload) {
      state.students = payload;
    },
    setStudentsAssessment(state, payload) {
      state.studentsAssessment = payload;
    },
    changeSection(state, payload) {
      state.section = payload;
    },
    setAdvisory(state, payload) {
      state.advisory = payload;
    },
    resetPoints(state) {
      state.studentPoints = [];
    },
    setPoints(state, { id, studentId, multiplier, payload }) {
      const point = _.find(state.studentPoints, {
        questionId: id,
        studentId: studentId,
      });
      const points = state.studentPoints;
      const pointIndex = points.indexOf(point);
      const validPoint =
        multiplier >= payload.value ? payload.value : multiplier;
      if (pointIndex >= 0) {
        points[pointIndex][payload.key] = payload.value;
        state.studentPoints = [...state.studentPoints];
      } else {
        state.studentPoints = [
          ...state.studentPoints,
          {
            questionId: id,
            studentId: studentId,
            [payload.key]: validPoint,
          },
        ];
      }
    },
    setStudentsLogs(state, payload) {
      if (payload.id) {
        const studentsAssessment = state.studentsAssessment;
        const student = _.find(state.studentsAssessment, { id: payload.id });
        const studentIndex = studentsAssessment.indexOf(student);
        const studentLog =
          state.studentsAssessment[studentIndex].studentAssessments[0];
        studentLog.logs = payload.logs;
        studentLog.score = payload.score;
      }

      state.studentsAssessment = [...state.studentsAssessment];
    },
  },
  actions: {
    getStudents({ state, commit }) {
      axios
        .get("/teacher/students", { params: state.studentsQuery })
        .then((res) => {
          if (res.data.status == "success") {
            commit("setStudents", res.data.students);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },

    getStudentsAssessment({ state, commit }, payload) {
      axios
        .get(`/teacher/students_assessment/${payload}`, {
          params: state.studentsQuery,
        })
        .then((res) => {
          if (res.data.status == "success") {
            commit("setStudentsAssessment", res.data.studentsAssessment);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },
    exportCsv({ commit }, payload) {
      const params = Object.keys(payload)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(payload[k]);
        })
        .join("&");

      window.open(`${config.apiUrl}/teacher/export-grades?${params}`);
    },

    async getSectionsBySubjectId({ commit }, payload) {
      await axios
        .get(`/teacher/subject/${payload}/sections`)
        .then((res) => {
          if (res.data.status == "success") {
            commit("setSections", res.data.sections);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },
    getTeacherAdvisory({ commit }) {
      axios
        .get("/teacher/advisory")
        .then((res) => {
          if (res.data.status == "success") {
            commit("setAdvisory", res.data.advisory);
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },
    async updateLogs({ state, commit }, payload) {
      const updatedScores = state.studentPoints;
      const logId = payload.id;

      return await axios
        .post(`/teacher/update_score/${logId}`, {
          params: updatedScores,
        })
        .then((res) => {
          const data = res.data.data;
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
            commit("setStudentsLogs", data);
            return true;
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
            commit("setStudentsLogs", data);
            return false;
          }
        })
        .finally((res) => {
          commit("resetPoints");
        });
    },
  },
};
export default studentsModule;
