import axios from "../../lib/axios";
import notify from "./../../utilities/notifications";

const calendarModule = {
  namespaced: true,
  state: {
    newEventDialog: false,
    eventDetailsDialog: false,
    events: [],
    holidays: [],
    eventDetails: {
      name: "",
      description: "",
      from: "",
      start: "",
      end: "",
      type: "",
    },
    eventsQuery: {
      query: "",
      type: "event",
      announcer: "teacher", // teacher/admin/head
      page: 1,
      limit: 10,
      offset: "",
      orderColumn: "createdAt",
      orderType: "asc",
      date: "",
      filterByAudience: "", //add value options(subject/section/department/public)- required for upcoming events/announcements / for search all  (optional)
      filterUpcoming: "", //yes-null
    },
  },
  getters: {},
  mutations: {
    setEventsQuery(state, payload) {
      state.eventsQuery = {
        ...state.eventsQuery,
        [payload.key]: payload.value,
      };
    },
    resetEventsQuery(state) {
      state.eventsQuery = {
        query: "",
        type: "event",
        announcer: "teacher",
        page: 1,
        limit: 10,
        offset: "",
        orderColumn: "createdAt",
        orderType: "asc",
        date: "",
        filterByAudience: "",
        filterUpcoming: "",
      };
    },
    newEventDetail(state, { key, value }) {
      state.eventDetails[key] = value;
    },
    toggleNewEventDialog(state, payload) {
      state.eventDetails = {
        date: payload?.date,
      };
      state.newEventDialog = !state.newEventDialog;
    },
    toggleEventDetailsDialog(state, event) {
      const eventDetail = event.event;
      state.eventDetails = {
        name: eventDetail.name,
        description: eventDetail.details,
        start: eventDetail.start,
        from: eventDetail.from,
        end: eventDetail.end,
        type: eventDetail.type,
      };
      state.eventDetailsDialog = !state.eventDetailsDialog;
    },
    setEvents(state, payload) {
      state.events = payload;
    },

    // name: "Company Meeting",
    // start: new Date(),
    // end: new Date(),
    // timed: true,
    // color: "main-yellow-bg",
    // type: "event",
    // details: "This is a Sample Event Detail, Information Inputs Here*",
    // from: "Teacher A",
    setHolidays(state, payload) {
      const parsedHolidays = payload.map((holiday) => {
        return {
          name: holiday.name[1].text,
          start: "",
          end: "",
          timed: false,
          color: "main-blue-bg",
          type: "holiday",
          details,
        };
      });

      state.holidays = parsedHolidays;
    },
  },
  actions: {
    newEvent({ commit }, payload) {
      axios
        .post("/announcements-events", payload)
        .then((res) => {
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Submit New Event Failed",
            message: err.detail,
          });
        });
    },
    async getEvents({ commit, state }) {
      await axios
        .get("/announcements-events", { params: state.eventsQuery })
        .then((res) => {
          if (res.data.status == "success") {
            commit("setEvents", res.data.announcementsEvents);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Fetching Calendar Events Failed",
            message: err.detail,
          });
        });
    },
    getHolidaysPerMonth({ commit }, payload) {
      const { month, year } = payload;
      // `https://kayaposoft.com/enrico/json/v2.0?action=getHolidaysForMonth&month=${month}&year=${year}&country=phl&holidayType=public_holiday`,

      axios
        .get("")
        .then((res) => {})
        .catch((err) => {});
    },
  },
};

export default calendarModule;
