const env = process.env.NODE_ENV || "development";
const config = require("@/config/config")[env];

import Axios from 'axios'
import localforage from '../plugins/localforage';
import { accessTokenName } from '../config/auth0';

const axios = Axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
})

axios.interceptors.request.use(
  async (config) => {
    const token = await localforage.getItem(accessTokenName);
    const auth = `Bearer ${token}`;
    
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }
  }
});

export default axios
