import {
  apiGetLessons,
  apiSubmitLesson,
  apiGetLessonById,
  apiDeleteLessonById,
  apiGetLessonComments,
  apiPostComment,
  apiApproveComment,
  apiUpsertVideoWatched,
  apiGetWatchedRecords,
} from "../../api/lessons";
import notify from "@/utilities/notifications";
import router from "@/router";
import { storage } from "@/plugins/firebase";
//import io from "socket.io-client";
import localforage from '../../plugins/localforage';
//const socket = io(process.env.VUE_APP_ASL_BASE_URL);

const lessonsModule = {
  namespaced: true,
  state: {
    lessons: [],
    lesson: {},
    lessonData: {},
    comments: [],
    watchedRecords: [],
    uploadProgress: 0,
    lessonsLoading: true,
  },
  getters: {
    getLessons: (state) => {
      return state.lessons;
    },
    getLessonData: (state) => {
      return state.lessonData;
    },
    getLessonTitles: (state) => {
      let taken = [];
      state.lessons.map((lesson) => taken.push(lesson.title.toLowerCase()));
      return taken;
    },
    getLessonsAutocomplete: (state) => {
      let lessonsAutocomplete = [];
      state.lessons.map((lesson) =>
        lessonsAutocomplete.push({
          gradeLevel: lesson.gradeLevel,
          text: lesson.name + " (" + lesson.gradeLevel + ")",
          value: lesson.id,
        })
      );

      return lessonsAutocomplete;
    },
  },
  mutations: {
    setUploadProgress(state, progress) {
      state.uploadProgress = Math.ceil(progress);
    },
    setLessons(state, lessons) {
      state.lessons = lessons;
    },
    setLessonsLoading(state, status) {
      state.lessonsLoading = status;
    },
    setLesson(state, lesson) {
      state.lesson = lesson;
    },
    setLessonData(state, lesson) {
      state.lessonData = lesson;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setWatchedRecords(state, watchedRecords) {
      state.watchedRecords = watchedRecords;
    },
    updateWatchedRecords(state, watchedRecord) {
      let studentWatchedRecord = state.watchedRecords.find(
        (stateWatchedRecord) => stateWatchedRecord.id === watchedRecord.id
      );

      studentWatchedRecord.watched = watchedRecord.watched;
    },
    addComment(state, comment) {
      comment = { ...comment, replies: [] };
      state.comments = [...state.comments, comment];
    },
    addReply(state, reply) {
      let comments = [];
      state.comments.map((comment) => {
        if (comment.id === reply.commentId) {
          comment.replies = [...comment.replies, reply];
        }
        comments.push(comment);
      });
      state.comments = comments;
    },
    videoWatched(state, watched) {
      if (
        state.lesson?.id === watched.lessonId &&
        state.lesson?.file === watched.videoFile
      ) {
        state.lesson.videoWatched = watched;
      }
    },
    clearLesson(state) {
      state.lesson = {};
    },
    clearLessonData(state) {
      state.lessonData = {};
    },
    clearComments(state) {
      state.comments = [];
    },
    clearWatchedRecords(state) {
      state.watchedRecords = [];
    },
  },
  actions: {
    async getWatchedRecords({ commit }, payload) {
      await apiGetWatchedRecords(payload)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setWatchedRecords", response.data.watchedRecords);
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async upsertVideoWatched({ commit }, payload) {
      await apiUpsertVideoWatched(payload)
        .then((response) => {
          if (response.data.status == "success") {
            const watched = {
              ...response.data.watched,
              lessonId: payload.lessonId,
            };
            commit("videoWatched", watched);
            // if (!payload.isTeacher) {
            //   if (payload.newEntry) {
            //     socket.emit("watched:new", payload.videoFile);
            //   } else {
            //     socket.emit("watched:update", payload);
            //   }
            // }
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async approveComment({ commit }, payload) {
      await apiApproveComment(payload)
        .then((response) => {
          if (response.data.status == "success") {
            //socket.emit("approved:new", payload);
          } else {
            //let user to retry request
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async postComment({ commit }, payload) {
      await apiPostComment(payload)
        .then((response) => {
          if (response.data.status == "success") {
            const comment = response.data.comment;
            if (!comment.commentId) {
              //socket.emit("comment:new", comment);
              commit("addComment", comment);
            } else {
              //socket.emit("reply:new", comment);
              commit("addReply", comment);
            }
          } else {
            //let user to retry request
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getLessonComments({ commit }, lessonId) {
      commit("clearComments");
      await apiGetLessonComments(lessonId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setComments", response.data.comments);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch lesson's comments",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    filterLessonById({ state, commit }, lessonId) {
      commit("clearLesson");
      const lesson = state.lessons?.find((lesson) => lesson.id == lessonId);
      if (!lesson) {
        notify({
          status: "info",
          title: "Info",
          message: "Lesson not found",
        });

        return router.push("/");
      }

      commit("setLesson", lesson);
    },
    async getLessonById({ commit }, lessonId) {
      commit("clearLessonData");
      await apiGetLessonById(lessonId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setLessonData", response.data.lesson);
            return true;
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch lesson data",
            });
            return false;
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          return false;
        });
    },
    async getLessons({ commit }, subjectId) {
      if (!subjectId) {
        return false;
      }
      commit("setLessonsLoading", true);
      await apiGetLessons(subjectId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setLessons", response.data.lessons);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("setLessonsLoading", false);
    },
    async submitLessonForm({ commit, dispatch }, payload) {
      const payloadFile = payload.file;
      const lessonFile = payload.lesson.file;

      commit("setLessonsLoading", true);
      if (payloadFile) {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const user = await localforage.getItem('userData');
        const fileName = `${user.id}-${timestamp}-${payloadFile.name}`;

        if (payload.isUpdating && lessonFile) {
          //check file data in firebase
          const currentFileMeta = await dispatch("getFileMeta", lessonFile);
          //check if file is different from the old one
          if (
            currentFileMeta.name.split("-")[2] != payloadFile.name &&
            currentFileMeta.size != payloadFile.size
          ) {
            //delete if different
            await dispatch("deleteFile", lessonFile);
            //upload changing file to firebase and set filename to be save in database
            payload.lesson.file = fileName;
            await dispatch("uploadFileToFirebase", {
              file: payloadFile,
              fileName,
            });
          } else {
            //if file is not changing, set filename to be save in database
            payload.lesson.file = currentFileMeta.name;
          }
        } else {
          payload.lesson.file = fileName;
          await dispatch("uploadFileToFirebase", {
            file: payloadFile,
            fileName,
          });
        }

        commit("setUploadProgress", 0);
      } else {
        //delete file if exist
        if (lessonFile) {
          await dispatch("deleteFile", lessonFile);
        }
        payload.lesson.file = "";
      }

      await apiSubmitLesson(payload.lesson, payload.isUpdating)
        .then((response) => {
          if (response.data.status == "success") {
            const successAction = payload.isUpdating ? "Updated" : "Added";
            notify({
              status: "success",
              title: "Success",
              message: `Lesson ${successAction} Successfully`,
            });
            dispatch("getLessons", payload.lesson.subjectId);
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setLessonsLoading", false);
    },
    async deleteFile({ commit }, fileName) {
      return await storage
        .ref()
        .child(`lesson-files/${fileName}`)
        .delete()
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async getFileMeta({ commit }, fileName) {
      return await storage
        .ref()
        .child(`lesson-files/${fileName}`)
        .getMetadata()
        .then((metadata) => {
          return metadata;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async getFileUrl({ commit }, fileName) {
      return await storage
        .ref()
        .child(`lesson-files/${fileName}`)
        .getDownloadURL()
        .then((url) => {
          return url;
        })
        .catch((error) => {
          console.log(error);
          notify({
            status: "info",
            title: "Info",
            message: "File not found",
          });
          return null;
        });
    },
    async uploadFileToFirebase({ commit }, payload) {
      return new Promise(function (resolve, reject) {
        const uploadLessonFile = storage
          .ref()
          .child(`lesson-files/${payload.fileName}`)
          .put(payload.file);

        uploadLessonFile.on(
          "state_changed",
          (snapshot) => {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            commit("setUploadProgress", progress);
          },
          (error) => {
            notify({
              status: "error",
              title: "Error",
              message: error,
            });
            reject();
          },
          () => {
            resolve();
          }
        );
      });
    },
    async deleteLessonById({ commit, dispatch }, payload) {
      commit("setLessonsLoading", true);
      await apiDeleteLessonById(payload.lessonId)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `Lesson Deleted Successfully`,
            });
            dispatch("getLessons", payload.subjectId);
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setLessonsLoading", false);
    },
  },
};

export default lessonsModule;
