const examModule = {
  namespaced: true,
  state: {
    newExam: false, // REMINDER CHECK IF NEEDED
    examInfoDialog: false,
    assignExamDialog: false,
  },
  getters: {},
  mutations: {
    toggleNewExam(state) {
      state.newExam = !state.newExam; // REMINDER CHECK IF NEEDED
    },
    toggleInfoExamDialog(state) {
      state.examInfoDialog = !state.examInfoDialog;
    },
    toggleAssignExamDialog(state) {
      state.assignExamDialog = !state.assignExamDialog;
    },
  },
  actions: {},
};

export default examModule;
