import axios from "@/lib/axios";

export function apiGetUsers(query) {
  return axios.get("/users", { params: query });
}

export function apiGetUserById(userId) {
  return axios.get("/user", { params: { userId: userId } });
}

export function apiSubmitUser(user, isUpdating) {
  if (isUpdating) {
    return axios.patch("/user", user);
  } else {
    return axios.post("/users", user);
  }
}

export function apiDeleteUserById(userId) {
  return axios.delete("/user", { params: { userId: userId } });
}

export function apiResetUserById(data) {
  return axios.patch(`/user/reset_password/${data.id}`, data);
}

export function apiImportUsers(importData) {
  return axios.post("/users/import_users", importData);
}

export function apiSyncUsers() {
  return axios.get("/users/sync");
}
