import axios from "../../lib/axios";
import notify from "./../../utilities/notifications";
import _ from "lodash";
import MESSAGES from "@/config/messages";
import router from "@/router";
import localforage from '../../plugins/localforage';

const subjectsModule = {
  namespaced: true,
  state: {
    newQuiz: false,
    subject: false,
    fetchingSubjects: false,
    fetchingSubject: false,
    studentTaskCount: [],
    subjects: [],
    department: "",
    lesson: {},
    lessons: [],
    assessments: [],
    assignedAssessmentDialog: false,
    assignedAssessmentStartDialog: false,
    quarterOptions: [],
    subjectsQuery: {
      query: "",
      page: null,
      limit: null,
      orderColumn: "name",
      orderType: "asc",
      semester: null,
    },
  },
  mutations: {
    toggleNewQuiz(state) {
      state.newQuiz = !state.newQuiz;
    },
    toggleAssignedLessonDialog(state) {
      state.assignedLessonDialog = !state.assignedLessonDialog;
    },

    toggleAssignedAssessmentDialog(state) {
      state.assignedAssessmentDialog = !state.assignedAssessmentDialog;
    },
    toggleAssignedAssessmenStartDialog(state) {
      state.assignedAssessmentStartDialog =
        !state.assignedAssessmentStartDialog;
    },
    setFetchingSubjects(state, status) {
      state.fetchingSubjects = status;
    },
    toggleFetchingSubjects(state) {
      state.fetchingSubjects = !state.fetchingSubjects;
    },
    toggleFetchingSubject(state) {
      state.fetchingSubject = !state.fetchingSubject;
    },
    setSubjects(state, payload) {
      state.subjects = payload;
    },
    setSubject(state, payload) {
      state.subject = payload;
    },
    setQuarterOptions(state, payload) {
      state.quarterOptions = payload;
    },
    setLesson(state, payload) {
      state.lesson = payload;
    },
    setLessons(state, payload) {
      state.lessons = payload;
    },
    setAssessments(state, payload) {
      state.assessments = payload;
    },
    setStudentTaskCount(state, payload) {
      state.studentTaskCount = payload;
    },
    setDepartment(state, payload) {
      state.department = payload;
    },
    setSubjectsQuery(state, payload) {
      state.subjectsQuery = {
        ...state.subjectsQuery,
        [payload.key]: payload.value,
      };
    },
  },
  actions: {
    async getSubjects({ commit }, payload) {
      commit("setFetchingSubjects", true);
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var response = await axios.get(`/${payload.role}/${payload.userId}/subjects`);
          if (response.data.status == "success") {
            let subjects = response.data.subjects;

            subjects = subjects.map((subject) => {
              return {
                ...subject,
                slug: subject.code,
              };
            });

            await localforage.setItem('userSubjects', subjects);
            commit("setSubjects", subjects);
          }
        } catch(e) {
          notify({
            status: "error",
            title: "Error",
            message: "Subjects Fetch Failed",
          });
        }
      } else {
        notify({
          title: "Info",
          status: "info",
          message: "You are currently offline. Some features may be unavailable.",
        });
        // if offline fetch from localForage
        try {
          var subjects = await localforage.getItem('userSubjects');
          if (subjects?.length === 0 || !subjects) {
            commit("setSubjects", []);
          } else {
            commit("setSubjects", subjects);
          }
        } catch (error) {
          notify({
            status: "error",
            title: "Fetch Subjects Failed",
            message: "No data available",
          });
        }
      }

      commit("setFetchingSubjects", false);
    },
    async updateSubjectsQuery({ commit, dispatch }, payload) {
      await commit("setSubjectsQuery", payload);
      if (payload.user == "student") {
        dispatch("getStudentSubjects");
      } else {
        dispatch("getTeacherSubjects");
      }
    },
    getTeacherSubjects({ state, commit }) {
      commit("toggleFetchingSubjects");

      axios
        .get("/teacher/subjects", { params: state.subjectsQuery })
        .then((response) => {
          if (response.data.statusCode == 200) {
            let subjects = response.data.subjects;

            subjects = subjects.map((subject) => {
              return {
                ...subject,
                bgColor: subject.color,
                textColor: subject.color,
                iconColor: subject.color,
                slug: subject.code,
              };
            });

            commit("setSubjects", subjects);
          }
        })
        .catch((e) => {
          notify({
            status: "error",
            title: "Subjects Fetch Failed",
            message: e.detail,
          });
        })
        .finally(() => {
          commit("toggleFetchingSubjects");
        });
    },
    async getDepartment({ state, commit, dispatch }, payload) {
      const role = payload.role === "teacher" ? "teacher" : "student";
      return await axios
        .get(`/${role}/department`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            const department = response.data.department;

            commit("setDepartment", department);
          }
        })
        .catch((e) => {
          notify({
            status: "error",
            title: "Department Fetch Failed",
            message: e.detail,
          });
        })
        .finally(() => {
          commit("setSubjectsQuery", {
            key: "semester",
            value: state.department === "JHS" ? 3 : 1,
          });
          if (role === "teacher") {
            dispatch("getTeacherSubjects");
          } else {
            dispatch("getStudentSubjects");
          }
        });
    },
    getSubjectByCode({ commit }, payload) {
      commit("toggleFetchingSubject");

      axios
        .get(`/teacher/subjects/by-code/${payload.code}`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            const data = response.data;

            const subject = {
              ...data.subject,
              bgColor: data.subject.color,
              textColor: data.subject.color,
              iconColor: data.subject.color,
              slug: data.subject.code,
            };

            commit("setSubject", subject);
          } else {
            commit("setSubject", false);
            notify({
              status: "error",
              title: MESSAGES.SUBJECT_NOT_FOUND_TITLE,
              message: MESSAGES.SUBJECT_NOT_FOUND_MESSAGE,
            });
            router.push("/workplace");
          }
        })
        .catch((e) => {
          commit("setSubject", false);
          notify({
            status: "error",
            title: "Subject Fetch Failed",
            message: e.detail,
          });
        })
        .finally(() => {
          commit("toggleFetchingSubject");
        });
    },
    async getStudentTaskCount({ commit }, subjectId) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var response = await axios.get(`/subject/${subjectId}/tasks-count`);
          if (response.data.statusCode == 200) {
            let tasksCount = response.data;

            tasksCount = {
              lessonsCount: tasksCount.lessonsCount,
              assessmentsCount: tasksCount.assessmentsCount,
            };

            await localforage.setItem('tasksCount', tasksCount);

            commit("setStudentTaskCount", tasksCount);
          }          
        } catch (error) {
          notify({
            status: "error",
            title: "Fetch Tasks Failed",
            message: error.detail,
          });
          router.push("/classroom");          
        }
      } else {
        try {
          var tasksCount = await localforage.getItem('tasksCount');
          if (!tasksCount) {
            notify({
              status: "error",
              title: "Fetch Tasks Failed",
              message: "No data available",
            });
          } else {
            commit("setStudentTaskCount", tasksCount);
          }
        } catch(e) {
          notify({
            status: "error",
            title: "Fetch Tasks Failed",
            message: "No data available",
          });
        }
      }
    },

    getSubjectLessonsPerQuarter({ state, commit }, payload) {
      axios
        .get(`/student/subject/lessons`, {
          params: {
            ...state.subjectsQuery,
            subjectId: payload.subjectId,
            quarter: payload.quarter,
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            commit("setLessons", response.data.lessons);
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Lessons Fetch Failed",
            message: err.detail,
          });
        });
    },

    getSubjectAssessmentsPerQuarter({ state, commit }, payload) {
      axios
        .get(`/student/subject/assessments`, {
          params: {
            ...state.subjectsQuery,
            subjectId: payload.subjectId,
            quarter: payload.quarter,
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            commit("setAssessments", response.data.assessments);
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Assessments Fetch Failed",
            message: err.detail,
          });
        });
    },
  },
};

export default subjectsModule;
