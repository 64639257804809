import {
  apiGetSubjects,
  apiSubmitSubject,
  apiGetSubjectById,
  apiDeleteSubjectById,
} from "../../api/subjects";
import notify from "@/utilities/notifications";
import axios from "../../lib/axios";

const subjectsModule = {
  namespaced: true,
  state: {
    subjects: [],
    subjectData: {},
    subjectTaskGrade: {},
    subjectsLoading: true,
  },
  getters: {
    getStudentsEnrolled: (state) => {
      let studentsEnrolled = [];
      if (state.subjectData.irregular?.length > 0) {
        studentsEnrolled = [
          ...state.subjectData.sections,
          {
            id: 0,
            name: "Irregular",
            students: state.subjectData.irregular,
          },
        ];
      } else {
        studentsEnrolled = state.subjectData.sections;
      }

      return studentsEnrolled;
    },
    getSubjects: (state) => {
      return state.subjects;
    },
    getSubjectTaskGrade: (state) => {
      return state.subjectTaskGrade;
    },
    getSubjectData: (state) => {
      return state.subjectData;
    },
    getSubjectCodes: (state) => {
      let taken = [];
      state.subjects.map((subject) => taken.push(subject.code));
      return taken;
    },
    getSubjectsAutocomplete: (state) => {
      let subjectsAutocomplete = [];
      state.subjects.map((subject) =>
        subjectsAutocomplete.push({
          gradeLevel: subject.gradeLevel,
          text: subject.name + " (" + subject.gradeLevel + ")",
          value: subject.id,
        })
      );

      return subjectsAutocomplete;
    },
  },
  mutations: {
    setSubjects(state, subjects) {
      state.subjects = subjects;
    },
    setSubjectsLoading(state, status) {
      state.subjectsLoading = status;
    },
    setSubjectData(state, subject) {
      state.subjectData = subject;
    },
    setSubjectTaskGrade(state, subjectTaskGrade) {
      state.subjectTaskGrade = subjectTaskGrade;
    },
    clearSubjectData: (state) => {
      state.subjectData = {};
    },
    clearSubjectTaskGrade: (state) => {
      state.subjectTaskGrade = {};
    },
  },
  actions: {
    async getSubjectTaskGradeById({ commit }, payload) {
      commit("clearSubjectTaskGrade");
      await axios
        .get(`/subject/${payload.subjectId}/tasks-grades`, {
          params: { assessmentId: payload.assessmentId },
        })
        .then((response) => {
          if (response.data.status == "success") {
            commit("setSubjectTaskGrade", response.data.subjectTaskGrade);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch data",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getSubjectById({ commit }, subjectId) {
      commit("clearSubjectData");
      await apiGetSubjectById(subjectId)
        .then((response) => {
          if (response.data.status == "success") {
            let subjectData = response.data.subject;
            subjectData.teacherIds = subjectData.teacherIds.map((teacher) => teacher.user_id)
            commit("setSubjectData", subjectData);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch subject data",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getSubjects({ commit }) {
      commit("setSubjectsLoading", true);
      await apiGetSubjects()
        .then((response) => {
          if (response.data.status == "success") {
            commit("setSubjects", response.data.subjects);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("setSubjectsLoading", false);
    },
    async submitSubjectForm({ commit, dispatch }, payload) {
      commit("setSubjectsLoading", true);
      await apiSubmitSubject(payload.subject, payload.isUpdating)
        .then((response) => {
          if (response.data.status == "success") {
            const successAction = payload.isUpdating ? "Updated" : "Added";
            notify({
              status: "success",
              title: "Success",
              message: `Subject ${successAction} Successfully`,
            });
            dispatch("getSubjects");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setSubjectsLoading", false);
    },
    async deleteSubjectById({ commit, dispatch }, subjectId) {
      commit("setSubjectsLoading", true);
      await apiDeleteSubjectById(subjectId)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `Subject Deleted Successfully`,
            });
            dispatch("getSubjects");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setSubjectsLoading", false);
    },
  },
};

export default subjectsModule;
