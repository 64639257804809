<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/mobile/dist/PNotifyMobile.css';
import '@pnotify/core/dist/Material.css';
import 'animate.css';
import 'material-design-icons/iconfont/material-icons.css';

export default {
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | SCALI LMS',
  },
  name: 'App',

  components: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');
* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}
.arvo {
  font-family: 'Arvo', sans-serif;
}
:root {
  --default-background: #f5f7fa;
  --main-pink: #dd5e84;
  --secondary-pink: #f4dae2;
  --main-purple: #bb57cb;
  --secondary-purple: #f8c9ff;
  --main-dark-blue: #0d47a1;
  --secondary-dark-blue: #90caf9;
  --main-brown: #6d4c41;
  --secondary-brown: #efebe9;
  --main-blue: #03a9f4;
  --secondary-blue: #e3f2fd;
  --main-green: #1b5e20;
  --secondary-green: #e8f5e9;
  --main-teal: #00bfa5;
  --secondary-teal: #e0f2f1;
  --main-red: #f96961;
  --secondary-red: #f7d7d8;
  --sky-blue: #83daf6;
  --main-black: #2e2e2e;
  --secondary-black: #bdbdbd;
  --main-white: #ffffff;
  --deep-space: #4b6973;
}

.secondary-black-text {
  color: var(--secondary-black) !important;
}
.main-pink-text {
  color: var(--main-pink) !important;
}
.secondary-pink-text {
  color: var(--secondary-pink) !important;
}
.main-purple-text {
  color: var(--main-purple) !important;
}
.secondary-purple-text {
  color: var(--secondary-purple) !important;
}
.main-dark-blue-text {
  color: var(--main-dark-blue) !important;
}
.secondary-dark-blue-text {
  color: var(--secondary-dark-blue) !important;
}

.main-brown-text {
  color: var(--main-brown) !important;
}
.secondary-brown-text {
  color: var(--secondary-brown) !important;
}

.default-background {
  background: var(--default-background) !important;
}
.main-white-text {
  color: var(--main-white);
}
.main-blue-text {
  color: var(--main-blue) !important;
}
.main-green-text {
  color: var(--main-green) !important;
}
.main-teal-text {
  color: var(--main-teal) !important;
}
.main-red-text {
  color: var(--main-red) !important;
}
.secondary-blue-text {
  color: var(--secondary-blue) !important;
}
.secondary-green-text {
  color: var(--secondary-green) !important;
}
.secondary-teal-text {
  color: var(--secondary-teal) !important;
}
.secondary-red-text {
  color: var(--secondary-red) !important;
}
.sky-blue-text {
  color: var(--sky-blue) !important;
}
.main-black-text {
  color: var(--main-black) !important;
}
.deep-space-text {
  color: var(--deep-space) !important;
}
.main-blue-bg {
  background: var(--main-blue) !important;
}
.main-green-bg {
  background: var(--main-green) !important;
}
.main-teal-bg {
  background: var(--main-teal) !important;
}
.main-red-bg {
  background: var(--main-red) !important;
}
.main-white-bg {
  background: var(--main-white);
}
.secondary-blue-bg {
  background: var(--secondary-blue) !important;
}
.secondary-green-bg {
  background: var(--secondary-green) !important;
}
.secondary-teal-bg {
  background: var(--secondary-teal) !important;
}
.secondary-red-bg {
  background: var(--secondary-red) !important;
}
.sky-blue-bg {
  background: var(--sky-blue) !important;
}
.main-black-bg {
  background: var(--main-black) !important;
}
.deep-space-bg {
  background: var(--deep-space) !important;
}
.main-pink-bg {
  background: var(--main-pink) !important;
}
.secondary-pink-bg {
  background: var(--secondary-pink) !important;
}
.main-purple-bg {
  background: var(--main-purple) !important;
}
.secondary-purple-bg {
  background: var(--secondary-purple) !important;
}
.main-brown-bg {
  background: var(--main-brown) !important;
}
.secondary-brown-bg {
  background: var(--secondary-brown) !important;
}
.secondary-black-bg {
  background: var(--secondary-black) !important;
}

.main-dark-blue-bg {
  background: var(--main-dark-blue) !important;
}
.secondary-dark-blue-bg {
  background: var(--secondary-dark-blue) !important;
}
.table-row {
  margin: 0px !important;
  .table-col {
    display: flex;
    align-items: center;
    border: 1px solid #dddddd !important;
    padding: 8px !important;
  }
}
</style>
