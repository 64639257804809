import axios from "@/lib/axios";

export function apiGetSections() {
  return axios.get("/sections");
}

export function apiGetSectionById(sectionId) {
  return axios.get(`/section/${sectionId}`);
}

export function apiSubmitSection(section, isUpdating) {
  if (isUpdating) {
    return axios.patch(`/section/${section.id}`, section);
  } else {
    return axios.post("/sections", section);
  }
}

export function apiDeleteSectionById(sectionId) {
  return axios.delete(`/section/${sectionId}`);
}

export function apiTransferSection(payload) {
  return axios.patch("/section/transfer-students", payload);
}
