const assignmentModule = {
  namespaced: true,
  state: {
    dialog: false,
  },
  mutations: {
    toggleDialog(state) {
      state.dialog = !state.dialog;
    },
  },
  actions: {},
};

export default assignmentModule;
