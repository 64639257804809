const quizModule = {
  namespaced: true,
  state: {
    quizInfoDialog: false,
    assignQuizDialog: false,
  },
  getters: {},
  mutations: {
    toggleQuizInfoDialog(state) {
      state.quizInfoDialog = !state.quizInfoDialog;
    },
    toggleAssignQuizDialog(state) {
      state.assignQuizDialog = !state.assignQuizDialog;
    },
  },
  actions: {},
};

export default quizModule;
