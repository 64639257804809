import axios from "@/lib/axios";

export function apiGetSubjects() {
  return axios.get("/get_subjects");
}

export function apiGetSubjectById(subjectId) {
  return axios.get(`/subject/${subjectId}`);
}

export function apiSubmitSubject(subject, isUpdating) {
  if (isUpdating) {
    return axios.patch(`/subject/${subject.id}`, subject);
  } else {
    return axios.post("/subjects", subject);
  }
}

export function apiDeleteSubjectById(subjectId) {
  return axios.delete(`/subject/${subjectId}`);
}
