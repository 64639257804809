import axios from "../../lib/axios";
import notify from "@/utilities/notifications";

const assignTaskModule = {
  namespaced: true,
  state: {
    assignTaskModal: false,
    // assignTaskQuery: {
    //   studentIds: [],
    //   assessmentIds: [],
    //   lessonIds: [],
    // },
  },
  getters: {},
  mutations: {
    toggleModal(state, payload) {
      if (payload) {
        state.assignTaskModal = payload;
      } else {
        state.assignTaskModal = !state.assignTaskModal;
      }
    },
    // resetAssignTaskQuery(state) {
    //   state.assignTaskQuery = {
    //     studentIds: [],
    //     assessmentIds: [],
    //     lessonIds: [],
    //   };
    // },
    // setAssignTaskQuery(state, payload) {
    //   state.assignTaskQuery = {
    //     ...state.assignTaskQuery,
    //     [payload.key]: [
    //       ...state.assignTaskQuery[payload.key].value,
    //       payload.value,
    //     ],
    //   };
    // },
  },
  actions: {
    assignTask({ state, commit }, { id, payload }) {
      axios
        .post(`/teacher/subject/${id}/assign-tasks`, payload)
        .then((res) => {
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },
  },
};

export default assignTaskModule;
