import axios from "../../lib/axios";
import notify from "@/utilities/notifications";
import router from "@/router/index";
import localforage from '../../plugins/localforage';
import { accessTokenName } from "../../config/auth0";

const authModule = {
  namespaced: true,
  state: {
    accessToken: '',
    userLoaded: false,
    user: {},
    btnLoading: false,
    weeklyReport: {},
    profile: {},
  },
  getters: {
    getUser (state) {
      return state.user;
    }
  },
  mutations: {
    setAccessToken(state, newAccessToken) {
      state.accessToken = newAccessToken;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserLoaded(state, status) {
      state.userLoaded = status;
    },
    toggleBtnLoading(state) {
      state.btnLoading = !state.btnLoading;
    },
    setBtnLoading(state, status) {
      state.btnLoading = status;
    },
    setWeeklyReport(state, payload) {
      state.weeklyReport = payload;
    },
    setProfile(state, payload) {
      state.profile = payload;
    },
    updateProfile(state, payload) {
      state.profile[payload.index] = payload.value;

      state.profile = { ...state.profile };
    },
  },
  actions: {
    async saveAccessToken({ commit }) {
      var isOnline = await localforage.getItem('onlineStatus');

      if (isOnline === true) {
        const token = await this._vm.$auth.getTokenSilently();
        commit('setAccessToken', token);
        await localforage.setItem(accessTokenName, token);
      }
    },
    async getUser({ commit, dispatch }) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        await this._vm.$auth.handleRedirectCallback();
        await dispatch('saveAccessToken');

        const response = await axios.get('/auth/profile');
        await localforage.setItem('userData', response.data.data);
        commit('setUser', response.data.data);
      } else {
        const userData = await localforage.getItem('userData');
        commit('setUser', userData);
      }
      
      commit('setUserLoaded', true);
    },
    async logout({ commit }) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        this._vm.$auth.logout({ logoutParams: { returnTo: window.location.origin } });
      } else {
        notify({
          status: "info",
          message: "Please check your connection and try again.",
        });
      }
    },
    doLogin({ commit }, { username, password }) {
      commit("setBtnLoading", true);

      axios
        .post("/auth/login", {
          username,
          password,
        })
        .then((response) => {
          const data = response.data;
          if (data.status == "success") {
            localStorage.setItem(
              "__asl_session_user",
              JSON.stringify(data.user)
            );

            let toRoute = "";

            switch (data.user.role) {
              case "teacher":
                toRoute = "/workplace";
                break;
              case "student":
                toRoute = "/classroom";
                break;
              case "admin":
                // TO DO: where to go for admin?
                toRoute = "/admin";
                break;
              default:
                toRoute = "/";
            }

            router.replace(toRoute);

            notify({
              status: "success",
              message: "Welcome back, " + data.user.firstName + "!",
            });
          } else {
            notify({ status: "error", message: data.message });
          }
        })
        .catch((e) => {
          notify({ status: "error", message: e.detail });
        });
      commit("setBtnLoading", false);
    },
    doLogout({ commit }, withCustomMessage = false) {
      commit("setBtnLoading", true);

      axios
        .get("/auth/logout")
        .then((response) => {
          const data = response.data;

          if (data.status == "success") {
            localStorage.removeItem("__asl_session_user");

            router.replace("/login");

            commit("resetState", null, { root: true });

            if (!withCustomMessage) {
              notify({ status: "success", message: data.message });
            } else {
              notify({ status: "info", message: withCustomMessage.message });
            }
          } else {
            notify({ status: "error", message: data.message });
          }
        })
        .catch((e) => {
          notify({ status: "error", message: e.detail });
        });
      commit("setBtnLoading", false);
    },
    async getWeeklyReport({ commit }) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        axios
        .get("/weekly-reports")
        .then((res) => {
          if (res.data.status == "success") {
            commit("setWeeklyReport", res.data.weeklyReport);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
      }
    },
    getProfile({ commit }) {
      axios
        .get("/auth/profile")
        .then((res) => {
          if (res.status == 200) {
            commit("setProfile", res.data);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },
    async submitProfile({ state, dispatch }, type) {
      let data = {};

      if (type == "account") {
        data = {
          email: state.profile.email,
          username: state.profile.username,
          currentPassword: state.profile.currentPassword,
          newPassword: state.profile.newPassword,
          confirmPassword: state.profile.confirmPassword,
        };
      } else if (type == "personal") {
        data = {
          firstName: state.profile.firstName,
          middleName: state.profile.middleName,
          lastName: state.profile.lastName,
          contactNo: state.profile.contactNo,
        };
      } else {
        data = {
          guardian: state.profile.guardian,
          guardianContactNo: state.profile.guardianContactNo,
        };
      }

      return await axios
        .patch("/profile/update", data, { params: { type } })
        .then((response) => {
          const data = response.data;
          if (data.status == "success") {
            if (type == "account") {
              // logout required
              dispatch("doLogout", true);
            }

            notify({
              status: "success",
              message: `${data.message}${
                type == "account"
                  ? " Log in again using your new username and/or password."
                  : ""
              }`,
            });

            return true;
          } else {
            notify({ status: "error", message: data.message });

            return false;
          }
        })
        .catch((e) => {
          notify({
            status: "error",
            message: e.response?.data?.message || e.detail,
          });

          return false;
        });
    },
  },
};

export default authModule;
