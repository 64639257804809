import axios from "../../lib/axios";
import notify from "@/utilities/notifications";
import router from "@/router";

const modulesModule = {
  namespaced: true,
  state: {
    moduleDialog: false,
    updateModuleDialog: false,
    assignModuleDialog: false,
    deleteModuleDialog: false,
    module: {
      title: "",
      module: "",
      file: null,
      subjectId: "",
      teacherId: "",
      quarter: "",
      assessmentIds: [],
    },
    modules: [],
    modulesQuery: {
      query: "",
      page: 1,
      limit: 10,
      orderColumn: "title",
      orderType: "asc",
      filterByQuarter: "",
      subjectId: null,
    },
    selectedModule: {},
    fetchingModules: false,
    modulesTotalCount: -1,
  },
  getters: {},
  mutations: {
    setModule(state, payload) {
      state.module = { ...state.module, [payload.key]: payload.value };
    },
    setModules(state, payload) {
      state.modules = payload.lessons;
      state.modulesTotalCount = payload.modulesTotalCount;
    },
    toggleFetchingModules(state) {
      state.fetchingModules = !state.fetchingModules;
    },
    resetModule(state) {
      state.module = {
        title: "",
        module: "",
        file: null,
        subjectId: "",
        teacherId: "",
        quarter: "",
        assessmentIds: [],
      };
    },
    toggleModuleDialog(state) {
      state.moduleDialog = !state.moduleDialog;
    },
    toggleUpdateModuleDialog(state) {
      state.updateModuleDialog = !state.updateModuleDialog;
    },
    toggleAssignModuleDialog(state) {
      state.assignModuleDialog = !state.assignModuleDialog;
    },
    toggleDeleteModuleDialog(state) {
      state.deleteModuleDialog = !state.deleteModuleDialog;
    },
    setSelectedModule(state, payload) {
      state.selectedModule = payload;
    },
    setModulesQuery(state, payload) {
      state.modulesQuery = {
        ...state.modulesQuery,
        [payload.key]: payload.value,
      };
    },
    resetModulesQuery(state) {
      state.modulesQuery = {
        query: "",
        page: 1,
        limit: 10,
        orderColumn: "title",
        orderType: "asc",
        filterByQuarter: state.modulesQuery.filterByQuarter || "",
        subjectId: null,
      };
    },
  },
  actions: {
    async updateModulesQuery({ commit, dispatch }, payload) {
      await commit("setModulesQuery", payload);
      dispatch("fetchModules");
    },
    fetchModules({ commit, state }, payload) {
      commit("toggleFetchingModules");
      axios
        .get(`/teacher/lessons`, {
          params: state.modulesQuery,
        })
        .then((response) => {
          if (response.data.status == "success") {
            commit("setModules", {
              lessons: response.data.lessons,
              modulesTotalCount: response.data.totalRecords,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.message}`,
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        })
        .finally(() => {
          commit("toggleFetchingModules");
        });
    },
    async uploadModule({ commit, state }) {
      const module = state.module;
      const data = new FormData();

      if (!module.module && !module.file) {
        return notify({
          status: "error",
          title: "Failed Uploading",
          message: `There should be a Module Content or atleast 1 module file`,
        });
      }

      data.set("title", module.title);
      data.set("subjectId", module.subjectId);
      data.set("module", module.module);
      data.set("lessonFile", module.file);
      module.assessmentIds.forEach((id) => {
        data.set("assessmentIds[]", id);
      });
      data.set("quarter", module.quarter);

      return await axios
        .post("/teacher/lessons", data)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${response.data.message}`,
            });

            commit("resetModule");

            router.push({ name: "ModuleBank" });

            return true;
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.error}`,
            });

            return false;
          }
        })
        .catch((e) => {
          notify({
            status: "error",
            title: "Error",
            message: e.detail,
          });

          return false;
        });
    },

    async updateModule({ commit, state, dispatch }) {
      const module = state.module;
      const data = new FormData();

      data.set("title", module.title);
      data.set("subjectId", module.subjectId);
      data.set("module", module.module);
      data.set("quarter", module.quarter);
      module.assessmentIds.forEach((id) => {
        data.set("assessmentIds[]", id);
      });
      // if (typeof module.file == "object") {
      data.set("lessonFile", module.file);
      // }
      return await axios
        .patch(`/teacher/lesson/${module.id}`, data)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${response.data.message}`,
            });

            commit("toggleUpdateModuleDialog");
            commit("resetModule");

            return true;
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.error}`,
            });

            return false;
          }
        })
        .catch((e) => {
          notify({
            status: "error",
            title: "Error",
            message: e.detail,
          });

          return false;
        });
    },
    async deleteModule({ commit }, payload) {
      await axios
        .delete(`/teacher/lesson/${payload.id}`, payload)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${response.data.message}`,
            });
            commit("toggleDeleteModuleDialog");
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        });
    },

    async assignModuleAll({ state, commit }, payload) {
      await axios
        .post(`/teacher/assign/lesson/${payload.id}`, payload)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${response.data.message}`,
            });
            commit("toggleAssignModuleDialog");
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Error",
            message: err.detail,
          });
        })
        .finally(() => {
          state.selectedModule.assigned =
            payload.assign === true ? false : true;
        });
    },
  },
};

export default modulesModule;
