module.exports = {
  // Assessment
  SCHEDULE_ERROR_TITLE: "Schedule Error",
  SCHEDULE_ERROR_MESSAGE:
    "If an assessment is scheduled, it must have a start and an end date",
  SCHEDULE_INVALID_MESSAGE:
    "The schedule's end date cannot be equal or less than the start date",

  // Subject
  SUBJECT_NOT_FOUND_TITLE: "Subject Not Found",
  SUBJECT_NOT_FOUND_MESSAGE: "The subject you tried to access does not exist.",
};
