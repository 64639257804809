const nonGradedModule = {
  namespaced: true,
  state: {
    section: null,
  },
  getters: {},
  mutations: {
    changeSection(state, section) {
      state.section = section;
    },
  },
  actions: {},
};

export default nonGradedModule;
