import { defaults, alert, defaultStack, defaultModules } from "@pnotify/core";
import * as PNotifyAnimate from "@pnotify/animate";
import * as PNotifyMobile from "@pnotify/mobile";

defaults.styling = "material";
defaults.icons = "material";

const notify = function ({ title, status, message }) {
  defaultStack.close();
  alert({
    type: status,
    title: title || undefined,
    text: message,
    modules: new Map([
      ...defaultModules,
      [
        PNotifyAnimate,
        {
          inClass: null,
          outClass: null,
        },
      ],
      [PNotifyMobile],
    ]),
  });
};

export default notify;
