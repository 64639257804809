const gradesModule = {
  namespaced: true,
  state: {
    yearLevel: "1st Year",
    ptPercentage: 0,
    wwPercentage: 0,
    percentageDialog: false,
    assessmentDialog: false,
  },
  getters: {},
  mutations: {
    changeYearLevel(state, year) {
      state.yearLevel = `${year.year} Year`;
    },
    togglePercentageDialog(state) {
      state.percentageDialog = !state.percentageDialog;
    },
    setPercentage(state, { type, percentage }) {
      if (type == "Performance Tasks") {
        state.ptPercentage = percentage;
        state.wwPercentage = 100 - percentage;
      } else {
        state.wwPercentage = percentage;
        state.ptPercentage = 100 - percentage;
      }
    },
    toggleAssessmentDialog(state) {
      state.assessmentDialog = !state.assessmentDialog;
    },
  },
  actions: {},
};

export default gradesModule;
