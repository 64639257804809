import {
  apiGetSections,
  apiSubmitSection,
  apiGetSectionById,
  apiDeleteSectionById,
  apiTransferSection,
} from "../../api/sections";
import notify from "@/utilities/notifications";

const sectionsModule = {
  namespaced: true,
  state: {
    sections: [],
    section: {},
    sectionsLoading: true,
  },
  getters: {
    getSections: (state) => {
      return state.sections;
    },
    getSectionsAutocomplete: (state) => {
      let sectionsAutocomplete = [];
      state.sections.map((section) =>
        sectionsAutocomplete.push({
          gradeLevel: section.gradeLevel,
          text: section.name + " (" + section.gradeLevel + ")",
          value: section.id,
        })
      );

      return sectionsAutocomplete;
    },
    getSection: (state) => {
      return state.section;
    },
  },
  mutations: {
    setSectionsState(state, sections) {
      state.sections = sections;
    },
    setSectionsLoadingState(state, status) {
      state.sectionsLoading = status;
    },
    setSection(state, section) {
      state.section = section;
    },
    clearSection: (state) => {
      state.section = {};
    },
  },
  actions: {
    async getSectionById({ commit }, sectionId) {
      commit("clearSection");
      await apiGetSectionById(sectionId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setSection", response.data.section);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch section",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getSections({ commit }) {
      commit("setSectionsLoadingState", true);
      await apiGetSections()
        .then((response) => {
          if (response.data.length > 0) {
            commit("setSectionsState", response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("setSectionsLoadingState", false);
    },
    async submitSectionForm({ commit, dispatch }, payload) {
      commit("setSectionsLoadingState", true);
      await apiSubmitSection(payload.section, payload.isUpdating)
        .then((response) => {
          if (response.data.status == "success") {
            const successAction = payload.isUpdating ? "Updated" : "Added";
            notify({
              status: "success",
              title: "Success",
              message: `Section ${successAction} Successfully`,
            });
            dispatch("getSections");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setSectionsLoadingState", false);
    },
    async deleteSectionById({ commit, dispatch }, sectionId) {
      commit("setSectionsLoadingState", true);
      await apiDeleteSectionById(sectionId)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `Section Deleted Successfully`,
            });
            dispatch("getSections");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setSectionsLoadingState", false);
    },
    async transferSection({ commit }, payload) {
      await apiTransferSection(payload)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: response.data.status,
              title: "Success",
              message: response.data.message,
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
  },
};

export default sectionsModule;
