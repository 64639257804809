const activityModule = {
  namespaced: true,
  state: {
    activityInfoDialog: false,
    assignActivityDialog: false,
  },
  getters: {},
  mutations: {
    toggleInfoActivityDialog(state) {
      state.activityInfoDialog = !state.activityInfoDialog;
    },
    toggleAssignActivityDialog(state) {
      state.assignActivityDialog = !state.assignActivityDialog;
    },
  },
  actions: {},
};

export default activityModule;
