import axios from "@/lib/axios";

export function apiGetStrands() {
  return axios.get("/strands");
}

export function apiGetStrandById(strandId) {
  return axios.get(`/strand/${strandId}`);
}

export function apiSubmitStrand(strand, isUpdating) {
  if (isUpdating) {
    return axios.patch(`/strand`, strand);
  } else {
    return axios.post("/strands", strand);
  }
}

export function apiDeleteStrandById(strandId) {
  return axios.delete(`/strand/${strandId}`);
}
