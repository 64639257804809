const env = process.env.NODE_ENV || "development";
const config = require("@/config/config")[env];

import {
  apiGetUsers,
  apiSubmitUser,
  apiGetUserById,
  apiDeleteUserById,
  apiResetUserById,
  apiImportUsers,
  apiSyncUsers,
} from "../../api/users";
import notify from "@/utilities/notifications";

const usersModule = {
  namespaced: true,
  state: {
    users: [],
    students: [],
    userData: {},
    usersLoading: true,
  },
  getters: {
    getUsernamesTaken: (state) => {
      let taken = [];
      state.users.map((user) => taken.push(user.username));
      return taken;
    },
    getEmailsTaken: (state) => {
      let taken = [];
      state.users.map((user) => taken.push(user.email));
      return taken;
    },
    getUserData: (state) => {
      return state.userData;
    },
    getTeachersAutocomplete: (state) => {
      let teachersAutocomplete = [];
      state.users.map((user) => {
        if (user.role === "teacher") {
          teachersAutocomplete.push({
            text: user.firstName + " " + user.lastName,
            value: user.id,
          });
        }
      });
      return teachersAutocomplete;
    },
    getStudents: (state) => {
      return state.students;
    },
  },
  mutations: {
    setUsersState(state, users) {
      state.users = users;
    },
    setStudents(state, students) {
      state.students = students;
    },
    setUsersLoadingState(state, status) {
      state.usersLoading = status;
    },
    setUserData(state, user) {
      state.userData = user;
    },
    clearUserData: (state) => {
      state.userData = {};
    },
  },
  actions: {
    async syncUsers({ dispatch }) {
      try {
        const response = await apiSyncUsers();
        notify({
          status: "success",
          message: response.data.message,
        });
        dispatch("getUsers");
      } catch (error) {
        notify({
          status: "error",
          message: error.data.error,
        }); 
      }
    },
    async getUserById({ commit }, userId) {
      commit("clearUserData");
      await apiGetUserById(userId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setUserData", response.data.user);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch user data",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getUsers({ commit }, payload) {
      let query = {
        role: "",
        sectionId: "",
      };

      if (payload) {
        query = {
          role: payload.role,
          sectionId: payload.sectionId,
        };
      } else {
        commit("setUsersLoadingState", true);
      }
      await apiGetUsers(query)
        .then((response) => {
          if (response.data.status == "success") {
            if (query.role == "student") {
              commit("setStudents", response.data.users);
            } else {
              commit("setUsersState", response.data.users);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("setUsersLoadingState", false);
    },
    async submitUserForm({ commit, dispatch }, payload) {
      commit("setUsersLoadingState", true);
      await apiSubmitUser(payload.user, payload.isUpdating)
        .then((response) => {
          if (response.data.status == "success") {
            const successAction = payload.isUpdating ? "Updated" : "Added";
            notify({
              status: "success",
              title: "Success",
              message: `User ${successAction} Successfully`,
            });
            dispatch("getUsers");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setUsersLoadingState", false);
    },
    async resetUserById({ commit, dispatch }, data) {
      commit("setUsersLoadingState", true);
      await apiResetUserById(data)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `Password Reset Success`,
            });
            dispatch("getUsers");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setUsersLoadingState", false);
    },
    async deleteUserById({ commit, dispatch }, userId) {
      commit("setUsersLoadingState", true);
      await apiDeleteUserById(userId)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `User Deleted Successfully`,
            });
            dispatch("getUsers");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setUsersLoadingState", false);
    },
    downloadFileCSV({ commit }, role) {
      window.open(`${config.apiUrl}/import_users?role=${role}`);
    },
    async importUsers({ dispatch }, importData) {
      const data = new FormData();
      if (importData.role == "student") {
        data.set("sectionId", importData.sectionId);
      }
      data.set("role", importData.role);
      data.set("uploadFile", importData.uploadFile);

      await apiImportUsers(data)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${response.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${response.data.error}`,
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });

      dispatch("getUsers");
    },
  },
};

export default usersModule;
