import axios from "@/lib/axios";

export function apiGetLessons(subjectId) {
  return axios.get(`/lessons/${subjectId}`);
}

export function apiGetLessonById(lessonId) {
  return axios.get(`/lesson/${lessonId}`);
}

export function apiGetLessonComments(lessonId) {
  return axios.get(`/lesson/${lessonId}/comments`);
}

export function apiPostComment(payload) {
  return axios.post("/lesson/comment", payload);
}

export function apiApproveComment(payload) {
  return axios.patch(`/lesson/comment/approve/${payload.commentId}`, {
    status: payload.status,
  });
}

export function apiSubmitLesson(lesson, isUpdating) {
  if (isUpdating) {
    return axios.patch(`/teacher/lesson/${lesson.id}`, lesson);
  } else {
    return axios.post("/teacher/lessons", lesson);
  }
}

export function apiDeleteLessonById(lessonId) {
  return axios.delete(`/teacher/lesson/${lessonId}`);
}

export function apiUpsertVideoWatched(payload) {
  if (payload.newEntry) {
    return axios.post(`/video_watched`, payload);
  } else {
    return axios.patch(`/video_watched/${payload.id}`, payload);
  }
}

export function apiGetWatchedRecords(lessonId) {
  return axios.get(`/watched_records/${lessonId}`);
}
