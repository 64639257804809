import Vue from "vue";
import VueRouter from "vue-router";
import notify from "@/utilities/notifications";
import { authGuard } from '../auth/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: () => import("@/views/LandingPage.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/callback",
    name: "Callback",
    component: () => import("@/views/CallbackPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/Main.vue"),
    beforeEnter: authGuard,
    children: [
      {
        path: "/profile",
        name: "Profile",
        meta: {
          allowedRoles: ["any"],
        },
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "/admin",
        name: "Dashboard",
        meta: {
          allowedRoles: ["admin"],
        },
        component: () => import("@/views/AdminDashboard"),
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          allowedRoles: ["admin"],
        },
        component: () => import("@/views/UserIndex"),
      },
      {
        path: "/subjects",
        name: "Subjects",
        meta: {
          allowedRoles: ["admin"],
        },
        component: () => import("@/views/SubjectIndex"),
      },
      {
        path: "/sections",
        name: "Sections",
        meta: {
          allowedRoles: ["admin"],
        },
        component: () => import("@/views/SectionIndex"),
      },
      {
        path: "/strands",
        name: "Strands",
        meta: {
          allowedRoles: ["admin"],
        },
        component: () => import("@/views/StrandIndex"),
      },
      {
        path: "/workplace",
        name: "Workplace",
        meta: {
          allowedRoles: ["teacher"],
        },
        component: () => import("@/views/Workplace.vue"),
      },
      {
        path: "/classroom",
        name: "Classroom",
        meta: {
          allowedRoles: ["student"],
        },
        component: () => import("@/views/Classroom.vue"),
      },
      {
        path: "/classroom/:slug",
        component: () => import("@/views/SubjectPage"),
        meta: {
          allowedRoles: ["student"],
        },
        children: [
          {
            path: "/classroom/:slug/lessons",
            name: "LessonAssigned",
            component: () => import("@/views/LessonAssigned"),
          },
          {
            path: "/classroom/:slug/lessons/:id",
            name: "LessonInformation",
            component: () => import("@/views/LessonPage"),
          },
          {
            path: "/classroom/:slug/assessments",
            name: "AssessmentAssigned",
            component: () => import("@/views/AssessmentAssigned"),
          },
          {
            path: "/classroom/:slug/assessments/form",
            name: "TakeAssessment",
            component: () => import("@/views/AssessmentProper"),
          },
        ],
      },
      {
        path: "/workplace/:slug",
        component: () => import("@/views/Subject.vue"),
        meta: {
          allowedRoles: ["teacher"],
        },
        children: [
          {
            path: "/workplace/:slug/students",
            name: "Students",
            component: () => import("@/views/StudentIndex"),
          },
          {
            path: "/workplace/:slug/grades",
            name: "Grades",
            component: () => import("@/views/AssessmentGrading"),
          },
          {
            path: "/workplace/:slug/modules",
            name: "Modules",
            component: () => import("@/views/LessonIndex"),
          },
          {
            path: "/workplace/:slug/modules/:id",
            name: "Module",
            component: () => import("@/views/LessonPage"),
          },
          {
            path: "/workplace/:slug/assessment",
            name: "Assessment",
            component: () => import("@/views/AssessmentIndex"),
          },
          {
            path: "/workplace/:slug/assessment/create",
            name: "CreateAssessment",
            component: () => import("@/components/AssessmentForm"),
          },
          {
            path: "/workplace/:slug/assessment/view",
            name: "ViewAssessment",
            component: () => import("@/components/AssessmentView"),
          },
          {
            path: "/workplace/:slug/assessment/update",
            name: "UpdateAssessment",
            component: () => import("@/components/AssessmentForm"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const user = localStorage.__asl_session_user;

//   if (to.matched.some((m) => m.meta.requiresAuth)) {
//     if (user == undefined || user == "") {
//       // no user is logged in
//       router.replace("/login");
//     } else {
//       // a user is logged in
//       // check if role is allowed
//       const isAllowed = to.matched.some((m) => {
//         const allowedRoles = m.meta.allowedRoles || [];
//         return (
//           allowedRoles.includes("any") ||
//           allowedRoles.includes(JSON.parse(user).role)
//         );
//       });

//       if (isAllowed == false) {
//         notify({
//           status: "error",
//           title: "Unauthorized",
//           message: "You are not allowed to access this page.",
//         });

//         router.replace("/");

//         return false;
//       }
//     }
//   }

//   if (
//     !to.matched.some((m) => m.meta.requiresAuth) &&
//     user != undefined &&
//     user != ""
//   ) {
//     if (JSON.parse(user).role == "teacher" && from.path != "/workplace") {
//       router.replace("/workplace");
//     } else if (
//       JSON.parse(user).role == "student" &&
//       from.path != "/classroom"
//     ) {
//       router.replace("/classroom");
//     }
//   }

//   next();
// });

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

export default router;
