import Vue from "vue";
import Vuex from "vuex";
import gradesModule from "./modules/gradesModule";
import announcementModule from "./modules/announcementModule";
import calendarModule from "./modules/calendarModule";
import socialModule from "./modules/socialModule";
import assignmentModule from "./modules/assignmentModule";
import authModule from "./modules/authModule";
import subjectsModule from "./modules/subjectsModule";
import examModule from "./modules/examModule";
import studentsModule from "./modules/studentsModule";
import createPersistedState from "vuex-persistedstate";
import assignTaskModule from "./modules/assignTaskModule";
import nonGradedModule from "./modules/nonGradedModule";
import activityModule from "./modules/activityModule";
import modulesModule from "./modules/modulesModule";
import quizModule from "./modules/quizModule";
import assessmentModule from "./modules/assessmentModule";
import usersModule from "./modules/usersModule";
import _subjectsModule from "./modules/_subjectsModule";
import sectionsModule from "./modules/sectionsModule";
import strandsModule from "./modules/strandsModule";
import lessonsModule from "./modules/lessonsModule";
import _ from "lodash";

const modules = {
  gradesModule,
  announcementModule,
  calendarModule,
  socialModule,
  assignmentModule,
  authModule,
  studentsModule,
  subjectsModule,
  examModule,
  assignTaskModule,
  nonGradedModule,
  activityModule,
  modulesModule,
  quizModule,
  assessmentModule,
  usersModule,
  sectionsModule,
  _subjectsModule,
  strandsModule,
  lessonsModule,
};

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    header: true,
    deleteDialog: false,
  },
  mutations: {
    toggleHeader(state) {
      state.header = !state.header;
    },
    deleteDialog(state) {
      state.deleteDialog = !state.deleteDialog;
    },
    resetState(state) {
      _.forOwn(modules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    },
  },
  actions: {},
  modules: _.cloneDeep(modules),
});
