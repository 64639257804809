import {
  apiGetStrands,
  apiSubmitStrand,
  apiGetStrandById,
  apiDeleteStrandById,
} from "../../api/strands";
import notify from "@/utilities/notifications";

const strandsModule = {
  namespaced: true,
  state: {
    strands: [],
    strand: {},
    strandsLoading: true,
  },
  getters: {
    getStrands: (state) => {
      return state.strands;
    },
    getStrand: (state) => {
      return state.strand;
    },
  },
  mutations: {
    setStrandsState(state, strands) {
      state.strands = strands;
    },
    setStrandsLoadingState(state, status) {
      state.strandsLoading = status;
    },
    setStrand(state, strand) {
      state.strand = strand;
    },
    clearStrand: (state) => {
      state.strand = {};
    },
  },
  actions: {
    async getStrandById({ commit }, strandId) {
      commit("clearStrand");
      await apiGetStrandById(strandId)
        .then((response) => {
          if (response.data.status == "success") {
            commit("setStrand", response.data.strand);
          } else {
            notify({
              status: "error",
              title: "Error",
              message: "Failed to fetch strand",
            });
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
        });
    },
    async getStrands({ commit }) {
      commit("setStrandsLoadingState", true);
      await apiGetStrands()
        .then((response) => {
          if (response.data.length > 0) {
            commit("setStrandsState", response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      commit("setStrandsLoadingState", false);
    },
    async submitStrandForm({ commit, dispatch }, payload) {
      commit("setStrandsLoadingState", true);
      await apiSubmitStrand(payload.strand, payload.isUpdating)
        .then((response) => {
          if (response.data.status == "success") {
            const successAction = payload.isUpdating ? "Updated" : "Added";
            notify({
              status: "success",
              title: "Success",
              message: `Strand ${successAction} Successfully`,
            });
            dispatch("getStrands");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setStrandsLoadingState", false);
    },
    async deleteStrandById({ commit, dispatch }, strandId) {
      commit("setStrandsLoadingState", true);
      await apiDeleteStrandById(strandId)
        .then((response) => {
          if (response.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `Strand Deleted Successfully`,
            });
            dispatch("getStrands");
          }
        })
        .catch((error) => {
          notify({
            status: "error",
            title: "Error",
            message: error.detail,
          });
          console.log(error);
        });
      commit("setStrandsLoadingState", false);
    },
  },
};

export default strandsModule;
