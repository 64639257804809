import axios from "../../lib/axios";
import notify from "./../../utilities/notifications";

const announcementModule = {
  namespaced: true,
  state: {
    dialog: false,
    announcement: {},
    announcements: [],
    totalRecords: "",
    announcementsQuery: {
      query: "",
      type: "announcement",
      announcer: "teacher", // teacher/admin/head
      page: 1,
      limit: 10,
      offset: "",
      orderColumn: "createdAt",
      orderType: "asc",
      date: "",
      filterByAudience: "", //add value options(subject/section/department/public)- required for upcoming events/announcements / for search all  (optional)
      filterUpcoming: "", //yes-null
    },
  },
  mutations: {
    setAnnouncementsQuery(state, payload) {
      state.announcementsQuery = {
        ...state.announcementsQuery,
        [payload.key]: payload.value,
      };
    },
    resetAnnouncementsQuery(state) {
      state.announcementsQuery = {
        query: "",
        type: "announcement",
        announcer: "teacher",
        page: 1,
        limit: 10,
        offset: "",
        orderColumn: "createdAt",
        orderType: "asc",
        date: "",
        filterByAudience: "",
        filterUpcoming: "",
      };
    },
    toggleDialog(state) {
      state.dialog = !state.dialog;
    },
    setAnnouncement(state, payload) {
      state.announcement = payload;
    },
    setAnnouncements(state, payload) {
      state.announcements = payload.data;
      state.totalRecords = payload.totalCount;
    },
  },
  actions: {
    async newAnnouncement({ commit }, payload) {
      await axios
        .post("/announcements-events", payload)
        .then((res) => {
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Submit New Announcement Failed",
            message: err.detail,
          });
        });
    },

    async updateAnnouncement({ commit }, payload) {
      await axios
        .patch(`/announcements-events/${payload.id}`, payload.data)
        .then((res) => {
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Update Announcement Failed",
            message: err.detail,
          });
        });
    },

    async deleteAnnouncement({ commit }, payload) {
      await axios
        .delete(`/announcements-events/${payload}`)
        .then((res) => {
          if (res.data.status == "success") {
            notify({
              status: "success",
              title: "Success",
              message: `${res.data.message}`,
            });
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Delete Announcement Failed",
            message: err.detail,
          });
        });
    },
    async getAnnouncements({ commit, state }) {
      await axios
        .get("/announcements-events", { params: state.announcementsQuery })
        .then((res) => {
          if (res.data.status == "success") {
            commit(
              "setAnnouncements",
              { data: res.data.announcementsEvents },
              { totalCount: res.data.totalRecords }
            );
          } else {
            notify({
              status: "error",
              title: "Error",
              message: `${res.data.error}`,
            });
          }
        })
        .catch((err) => {
          notify({
            status: "error",
            title: "Fetching Announcements Failed",
            message: err.detail,
          });
        });
    },
  },
};

export default announcementModule;
