const env = process.env.NODE_ENV || 'development';
const auth0Config = require('./config/auth0');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import DatetimePicker from 'vuetify-datetime-picker';
import { VueMasonryPlugin } from 'vue-masonry';
import { firestorePlugin } from 'vuefire';
import localforage from './plugins/localforage';
import { Auth0Plugin } from './auth';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './registerServiceWorker'

if (env == 'production') {
  window.addEventListener('contextmenu', (e) => e.preventDefault());
  document.addEventListener('keydown', (e) => {
    if (e.code === 'F12') {
      e.preventDefault();
    } else if (e.ctrlKey && e.shiftKey && e.code === 'KeyI') {
      e.preventDefault();
    }
  });
}

var handleNetworkOnline = async (status) => {
  var onlineStatus = await localforage.getItem('onlineStatus');
  if (onlineStatus !== status) {
    await localforage.setItem('onlineStatus', status);
  }
};

window.addEventListener('online', async () => {
  await handleNetworkOnline(true);
});

window.addEventListener('offline', async () => {
  await handleNetworkOnline(false);
});

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(DatetimePicker);
Vue.use(VueMasonryPlugin);
Vue.use(firestorePlugin);

localforage
  .ready()
  .then(function () {
    const isOnline = navigator.onLine;
    localforage
      .setItem('onlineStatus', isOnline)
      .then(function () {
        console.log('Online status stored successfully.');
      })
      .catch(function (error) {
        console.error('Error storing online status:', error);
      });

    if (isOnline === true) {
      Vue.use(Auth0Plugin, {
        domain: auth0Config.domain,
        clientId: auth0Config.clientId,
        authorizationParams: {
          audience: auth0Config.audience,
        },
        onRedirectCallback: (appState) => {
          router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
        },
      });
    }

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  })
  .catch(function (e) {
    console.log(e); // Handle initialization error
  });
