require("dotenv").config();

module.exports = {
  development: {
    baseUrl: process.env.VUE_APP_ASL_BASE_URL,
    apiUrl: process.env.VUE_APP_ASL_BASE_URL + "/api",
  },
  production: {
    baseUrl: process.env.VUE_APP_ASL_BASE_URL,
    apiUrl: process.env.VUE_APP_ASL_BASE_URL + "/api",
  },
};
